<template>
  <div>
    <main-nav :activeIndex="activeIndex"></main-nav>
    <div class="solution-header-container">
      <div class="solution-header-text">
        <h1 data-caption-delay="0" class="wow fadeInLeft">CAN/LIN测试</h1>
        <p data-caption-delay="100" class="wow fadeInRight">车载传统网络CAN(FD)/LIN/Flexray协议一致性自动化测试解决方案</p>
        <div class="solution-text-button wow fadeInUp" @click="linkUrl(chatUrl)"><div>立即咨询</div></div>
      </div>
    </div>
    <section class="solution-intros wow fadeInLeft">
      <div class="container">
        <div class="normal-title">解决方案介绍</div>
        <div class="all-intro">
          <el-row>
            <el-col :span="24">
              <div class="intro-text" style="width: 100%">
                <p>汽车网络为整车功能的实现提供了信息交互的“高速公路”，针对这条信息化“高速公路”设计合理性和稳定性的验证，是确保其可以稳定运行且畅通无阻的必要手段。CAN/CANFD/LIN网络作为车载通信领域最成熟的通信技术，仍然肩负着重要的历史责任。</p>
              </div>
              <div class="intro-text" style="width: 100%">
                <p>东信创智网络测试系统，主要集成Vector的网络开发、仿真及测试工具链，如 CANoe、VT System等，可实现CAN/CANFD/LIN车载网络协议一致性全自动化测试，标准参考OSI的7层模型，针对物理层、数据链路层、交互层及应用层进行测试。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="vehicle-solution-sections network-solution-function wow fadeInRight">
      <div class="container">
        <div class="normal-title">功能特点</div>
        <div class="all-function">
          <ul class="priority-list">
            <li>Vector专业的网络测试工具链的品质保证</li>
            <li>便捷的测试操作及管理UI，支持车型、样件、参数等必要的测试流程信息管理</li>
            <li>支持自动化生成报告及数据保存，可查询历史测试信息</li>
            <li>支持扩展多产品的协同工作平台功能</li>
            <li>核心设备采用国际一线品牌产品，如网络示波器、程控电源等，具有极高的稳定性及精度保证</li>
          </ul>
          <div class="function-table">
            <el-table
              :data="tableData"
              size="small"
              :header-cell-style="{background:'#296CA8',color:'#FFFFFF'}"
              border
            >
              <el-table-column
                label="覆盖协议及通讯功能"
                align="center"
              >
                <el-table-column
                  prop="firstCol"
                  label=""
                >
                </el-table-column>
                <el-table-column
                  prop="secondCol"
                  label="">
                </el-table-column>
                <el-table-column
                  prop="thirdCol"
                  label="">
                </el-table-column>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </section>
    <section class="electric-solution-components network-solution-components wow fadeInLeft">
      <div class="container">
        <div class="normal-title">核心组件</div>
        <div class="solution-components">
          <el-row class="components-content" :gutter="40">
            <el-col :xs="0" :sm="10" :md="10" :lg="10" :xl="10" style="text-align: right">
              <img src="../../../assets/images/solution-network-components.png" alt="">
            </el-col>
            <el-col :xs="24" :sm="14" :md="14" :lg="14" :xl="14">
              <div class="components-text">
                <div>
                  <h1>硬件组成</h1>
                  <ol>
                    <li>主体形式：标准19英寸机柜</li>
                    <li>供电分配及保护单元：电源总开关，过流保护，急停操作按钮</li>
                    <li>模块化电源、高精度网络示波器</li>
                    <li>自动化控制板卡：Vector VT System</li>
                    <li>控制器测试接口：MC 快速连接测试组件</li>
                  </ol>
                </div>
                <div style="margin-top: 50px">
                  <h1>软件组成</h1>
                  <ol>
                    <li>测试开发及执行软件：Vector CANoe</li>
                    <li>测试管理软件：StormEye</li>
                  </ol>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
    </section>
    <section class="network-solution-system-picture wow fadeInRight">
      <div class="container">
        <div class="normal-title">系统图片</div>
        <div class="all-system-picture">
          <div class="system-picture white-bg-pic-deal">
            <img src="../../../assets/images/solution-network-system-pic.png" alt="">
          </div>
        </div>
      </div>
    </section>
    <bzInfo style="margin-top: 0"/>
    <main-footer></main-footer>
  </div>
</template>

<script>
import mainNav from '@/components/MainNav'
import mainFooter from '@/components/MainFooter'
import bzInfo from '@/components/BzInfo'
export default {
  name: "network",
  components: {
    mainNav,
    mainFooter,
    bzInfo
  },
  data(){
    return {
      activeIndex: '2-3',
      swiperOption: {
        spaceBetween: 20, // 图片之间的间距
        centeredSlides: false, // 居中还是从图1开始
        slidesPerView: 4, // 一屏幕显示几个? 数字或者默认  auto 自动。
        notNextTick: true, // true:加载后允许触发事件 false:加载后不可以触发事件
        // loop: true, // 循环吗
        initialSlide: 0, // 从第几个开始
        autoplay: {
          delay: 5000, // 等5秒下一个
          disableOnInteraction: false // 中间滑动一下，取消自动吗？
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }, // 下按钮
        speed: 800, // 滑动时候动画的速度
        paginationClickable: true, // 下面按钮让点吗
        navigation: {
          prevEl: '.swiper-button-prev', // 左侧按钮
          nextEl: '.swiper-button-next' // 右侧按钮
        },
        // effect: 'fade', // 渐入效果
        watchSlidesProgress: true, // 开启这个参数来计算每个slide的progress
        watchSlidesVisibility: true  // 先要开启watchSlidesProgress参数，如果开启watchSlidesVisibility，则会在每个slide增加一个指示该slide的progress值得classname
        // autoHeight: true  // 图片高度自适应
      },
      tableData: [
        {
          firstCol: "CAN通信协议（ISO/企标）",
          secondCol: "LIN通信协议（SAE/企标）",
          thirdCol: "SAE J1939通信协议"
        },
        {
          firstCol: "USD诊断服务（ISO/企标）",
          secondCol: "BootLoader（企标）",
          thirdCol: "OSEK NM"
        },
        {
          firstCol: "AUTOSAR NM",
          secondCol: "Gateway Routing（企标）",
          thirdCol: ""
        },
        {
          firstCol: "FlexRay通信协议",
          secondCol: "FlexRay诊断服务",
          thirdCol: "FlexRay NM"
        },
      ],
      chatUrl:"https://doc.weixin.qq.com/forms/AOEAXQcgAAkAe4APAaVAD0jHqcmrcFRKf",
    }
  },
  mounted() {
    document.title = "汽车CAN/CANFD/FlexRay/LIN网络测试系统 - 解决方案 - 东信创智";
    new this.$wow.WOW().init();
  },
  methods: {
    linkUrl(url){
      window.open(url,'_blank') // 在新窗口打开外链接
      // window.location.href =this.indexro;  //在本页面打开外部链接
    },
  }
}
</script>

<style scoped>

</style>
